import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import {
    Aggregate, ColumnDirective, ColumnsDirective, CommandClickEventArgs, CommandColumn,
    CommandModel,
    dataBound,
    Edit,
    ExcelExport,
    Filter, FilterSettingsModel, GridComponent, Inject, PdfExport,
    Resize,
    SelectionSettingsModel,
    Sort,
    Toolbar, ToolbarItems, Page, SearchSettingsModel, SearchEventArgs, Search
} from "@syncfusion/ej2-react-grids";
import { Predicate, Query } from '@syncfusion/ej2-data';
import React, { useCallback, useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { loadFromApi } from "../../../api/baseApi";
import { getBankCodes, getDisableChanges, getIntent, getRejectData, updateAccountData, updateAmountData, updateBankCodesData } from "../../../api/intentApi";
import { formatDate, useCommonGridOptions, vaWrapper } from "../../../utils/gridUtils";
import { camelCaseToString } from "../../../utils/stringUtils";
import LoadingDisplay from "../../common/LoadingDisplay";
import MessagePopup from "../../common/MessagePopup";
import {
    AccumulationChartComponent, AccumulationSeriesCollectionDirective, AccumulationSeriesDirective,
     AccumulationLegend, PieSeries, AccumulationTooltip, IAccLoadedEventArgs, AccumulationTheme,
    AccumulationDataLabel
  } from '@syncfusion/ej2-react-charts';
import { GridViewContext } from "../../GridViewService";
import { AuthContext } from "../../../authentication/authContext";
import { updateChecklist } from "../../../api/homeApi";
export const IntentPage = () => {

    const { addToast } = useToasts();
    const { currentView } = React.useContext(GridViewContext);
    const setApiError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );
    const [intent, setIntent] = useState<any[]>([]);
    const [banks, setBanks] = useState([]);
    const [codes, setBankCodes] = useState([]);
    const [reject, setReject] = useState(false);
    const [rejectReason, setRejectReason] = useState('');
    const [showDialog, setShowDialog] = useState(false);
    const [updatedIntent, setUpdatedIntent] = useState([]);
    const [pieData, setPieData] = useState([]);
    const [columns, setColumns] = useState<any>([]);
    const [creditorName, setCreditorName] = useState('');

    useEffect(() => {
        let data: any  = {};
        let columns = Object.values(intent)[0] ? Object.keys(Object.values(intent)[0]) : [];
        columns = columns.filter((col) => col != 'userID')
        columns.length > 0 && setColumns(columns);
        intent.map((row : any)=> {
            let amnt = Number(row.amount.replace('$', '').replace(',', ''));
            if(data.hasOwnProperty(row.bankCode)){
                data[row.bankCode] = amnt + data[row.bankCode]
            }
            else {
                data[row.bankCode] = amnt
            }
        })
        setUpdatedIntent(data);
       let chartData: any =  Object.keys(data).map((key)=> {
            return {
                code: key,
                value: data[key]
            }
        })
        setCreditorName(intent?.[0]?.creditorName ?? '');       
        setPieData(chartData)
    }, [intent]);
    const [otherEnabled, setOtherEnabled] = useState(false);
    const handleChange = (e:any) =>{
       console.log(e)
    }
    const [isAccountUpdated, setIsAccountUpdated] = useState(false);
    const updateAccount  =(data: any) => {
        setLoading(true)
        let roleid= localStorage.getItem('id');
        const IntentPageApiWrapper = loadFromApi(setApiError);
        const loadUpdateAccountData = async () => {  
                     
            await IntentPageApiWrapper(() =>updateAccountData(data), setIsAccountUpdated);
            loadCompanyPageData();
            
        };               
        loadUpdateAccountData();
        const loadCompanyPageData = async () => { 

            const intentLoad = await IntentPageApiWrapper(()=>getIntent(roleid ?? loggedInUser?.creditorId), setIntent);
        };               
         
        setLoading(false)
    }

    const [isAmounttUpdated, setIsAmountUpdated] = useState(false);
    const updateAmount  =(data: any) => {
        let roleid= localStorage.getItem('id');
        const IntentPageApiWrapper = loadFromApi(setApiError);
        const loadUpdateAmountData = async () => {           
            await IntentPageApiWrapper(() =>updateAmountData({...data, userID: userId}), setIsAmountUpdated);
            loadCompanyPageData()
        };               
        loadUpdateAmountData();
        const loadCompanyPageData = async () => { 

            const intentLoad = await IntentPageApiWrapper(()=>getIntent(roleid ?? loggedInUser?.creditorId), setIntent);
            
        };  
    }

    const [ pageDisabled, setDisabled] = useState(false);
    useEffect(() => {
        setLoading(true)
        
        let roleid= localStorage.getItem('id');
        const IntentPageApiWrapper = loadFromApi(setApiError);
        const loadCompanyPageData = async () => { 

             await IntentPageApiWrapper(()=>getIntent(roleid ?? loggedInUser?.creditorId), setIntent);
        };               
        loadCompanyPageData();  
        const loadBankCodes = async () => { 
                       
           await IntentPageApiWrapper(()=>getBankCodes(roleid ?? loggedInUser?.creditorId), setBankCodes);
        };               
        loadBankCodes();  
        const disable = async () => { 
            await IntentPageApiWrapper(()=>getDisableChanges(roleid ?? loggedInUser?.creditorId), setDisabled);
            setLoading(false)
        };               
        disable();  
                      
    }, [setApiError]);
    useEffect(() => {
       let bankCodess: any = codes.map((code: any)=> {
            return code.reliantBankCode
        })
        setBanks(bankCodess)
    }, [codes])

    useEffect(()=> {
        setSelectedValue(banks[0])
    }, [banks])
    const [isRejected, setIsRejected] = useState(false)
    const [modalType, setModalType] = useState('')
    const [editedRow, setEditedRow] = useState({});
    const [bankData, setBankData] = useState({});
    const {  loggedInUser} = React.useContext(AuthContext);
    useEffect(() => {
        setIsRejected(false);
        if(reject){
            const IntentPageApiWrapper = loadFromApi(setApiError);
            const rejectData = async () => {            
              await IntentPageApiWrapper(() => getRejectData({
                    settlementId:id,
                    changedBy:  loggedInUser?.userId,
                    rejectReason: rejectReason,
                    userId: userId
                }), setIsRejected)
            };      
            rejectData()   
        }        
    },[reject])

    useEffect(()=> {
        if(isRejected){
            let data: any = intent.map((col: any) => {
                if(id && col.transactionID === id){
                    col.rejected = true;
                }
                return col;
            });
            setRejectValue('hy')
            setIntent(data);  
            
        
        }
    }, [isRejected])

    const onRejectClick = (args?: any) => {
        if(!args.rejected){
            setShowDialog(true); 
            setModalType('Reject')
            if(args?.transactionID ){
                setId(args?.transactionID); 
                setUserId(args?.userID);   
            } 
        }
       
    
    };

    const onEditClick = (args?: any) => {
        setEditedRow(args)
        setModalType('Edit')
        setShowDialog(true)
     
    

    };
    const [rejectValue, setRejectValue] = useState('Reject');

    const rejectCommand: CommandModel[] = [
        {
            title: 'Reject',
 
            buttonOption: { content: rejectValue, cssClass: 'e-btn btn-reject' }         
            
        }
    ];

    const editCommand: CommandModel[] = [
        {
            title: 'Edit',
            type: 'Edit',            
            buttonOption: {
                cssClass: 'e-outline edit-icon', 
            }
        }
    ];
    const selectionSettings: SelectionSettingsModel = { type: 'Multiple', checkboxOnly: true };
   
    const [initial, setInitial] = useState(true);
    const [id, setId] = useState('');
    const [userId, setUserId] = useState('')
    let dataBound = () =>{
        if(gridOptions.ref.current && currentView === 2)
        {
                gridOptions.ref.current.autoFitColumns([])
        }
    }

    const onCommandClick = (args?: CommandClickEventArgs) => {
       
        if (args && args.rowData && args.target ) {
            if((args.target.className.includes('edit-icon'))){
                setEditedRow(args.rowData)
                setUserId((args.rowData as any).userID) 
                setModalType('Edit')
                setShowDialog(true)
            }
            else{
                if(!(args.rowData as any).rejected){
                    setShowDialog(true); 
                    setModalType('Reject')
                    if((args.rowData as any).transactionID ){
                        setId((args.rowData as any).transactionID);  
                    } 
                }
            }
           
            const consumerData = args.rowData ;
        }
    };
    var exportFileName = creditorName + '_Next Day Payments';
    const gridOptions: any = useCommonGridOptions(exportFileName);
    
    const formatRejectedValue = (field: string, data?: any) => {
        let val = '';
        if(data){
            val = data['rejected'] === true ? 'Reject' : 'Rejected';
        }
        setRejectValue(val);
        return val;
    };
    const [loading, setLoading] = useState(false);
    const [enableConfirm, setEnableConfirm] = useState(false);
    const [settlementIds, setSettlementIds] = useState<any>([]);
    const onRowSelected = () => {
        let ref = gridOptions.ref;
        if(ref.current?.getSelectedRecords() &&
            ref.current?.getSelectedRecords().length > 0){
            
            setEnableConfirm(true)
        }else {
            setEnableConfirm(false)
        }
    }

    const searchOptions: SearchSettingsModel = {fields: [
        'transactionID',
      ],
      key: ''};
    const toolbarOptions: ToolbarItems[] = ['ExcelExport', 'PdfExport', 'CsvExport','Search'];

    let values: any;
    let key = '';
    let refresh = false;
    let removeQuery = false;
    let valueAssign = false;

    const actionBegin = (args: SearchEventArgs) => {
        if (args.requestType == 'searching') {
          const keys = args?.searchString?.split(',');
          var flag = true;
          var predicate: any;
          if (keys && keys?.length > 1) {
            if (gridOptions.ref.current.searchSettings.key !== '') {
              values = args.searchString;
              keys.forEach((key) => {
                key=key.trim();
                gridOptions.ref.current.getColumns().forEach((col: any) => {
                if(key.length > 1){
                  if (flag) {
                    predicate = new Predicate(col.field, 'contains', key, true);
                    flag = false;
                  } 
                  else {
                    var predic = new Predicate(col.field, 'contains', key, true);
                    predicate = predicate.or(predic);
                  }
                }
                });
              });
              gridOptions.ref.current.query = new Query().where(predicate);
              gridOptions.ref.current.searchSettings.key = '';
              refresh = true;
              valueAssign = true;
              removeQuery = true;
              gridOptions.ref.current.refresh();
            }
          }
        }
    }

   const actionComplete = (args: SearchEventArgs) => {
        if (args.requestType === 'refresh' && valueAssign) {
            if(gridOptions?.ref?.current )
             (document.getElementById(gridOptions?.ref?.current?.element.id + '_searchbar') as any).value = values;
          valueAssign = false;
        } else if (
            gridOptions?.ref?.current && (document.getElementById(gridOptions?.ref?.current?.element.id + '_searchbar') as any)?.value === '' &&
          args.requestType === 'refresh' &&
          removeQuery
        ) {
          gridOptions.ref.current.query = new Query();
          removeQuery = false;
          gridOptions.ref.current.refresh();
        }
      }


    const onRowDataBound = (args?: any) => {
        if (args && args.data) {
            let data = args.data as any;
            if (pageDisabled) {
                args.row
                ?.getElementsByClassName('e-editbutton')[0]
                    .classList.add('disabled');
            }
        }
    };
    const saveBankCodes = async () => {
        let ref = gridOptions.ref;
        let ids: any= [];
        if(ref.current?.getSelectedRecords() &&
            ref.current?.getSelectedRecords().length > 0){
            ids =  ref.current?.getSelectedRecords().map((record : any) => {
                return record.transactionID
            })
        }



        const IntentPageApiWrapper = loadFromApi(setApiError);
        let roleid= localStorage.getItem('id');
        await IntentPageApiWrapper(() =>updateBankCodesData({
            settlementID: ids,
            reliantBankCode: selectedValue,
            creditorID: roleid ?? loggedInUser?.creditorId
        }), setIsAccountUpdated);
        const loadCompanyPageData = async () => { 

            const intentLoad = await IntentPageApiWrapper(()=>getIntent(roleid ?? loggedInUser?.creditorId), setIntent);
        };               
        loadCompanyPageData(); 
    }
    const filterOptions: FilterSettingsModel = {
        type: 'Excel'
    };
    const bankCodes = ['Default', 'City']
    const [selectedValue, setSelectedValue] = useState('')
    const [graph, setGraph] = useState(false);
    const [completeResult, setCompleteResult] = useState<any>({});
    const {allowExcelExport, allowPdfExport, toolbarClick, pdfQueryCellInfo, excelQueryCellInfo} = gridOptions;

    const apiError = useCallback(
        (error?: string) => (error=='' || error) && addToast('We are currently unable to complete verification. Please contact our support team for more information.', { appearance: 'error', autoDismiss: true }),
        [addToast]
    );
    const onComplete = () =>{       
        const HomePageApiWrapper = loadFromApi(apiError);
        const IntentPageApiWrapper = loadFromApi(setApiError);
        let roleid= localStorage.getItem('id');
        const saveUserData = async () => { 
            setLoading(false)
            await HomePageApiWrapper(()=> updateChecklist({
                completedDate:new Date(),
                creditorID:localStorage.getItem('id') ?? loggedInUser?.creditorId,
                creditorName:creditorName,
                paymentDate:new Date(),
                verificationComplete: true,
                notifyCreditor: false,
            }), setCompleteResult);
            const disable = async () => { 
                await IntentPageApiWrapper(()=>getDisableChanges(roleid ?? loggedInUser?.creditorId), setDisabled);
                setLoading(false);
                let intentData = intent;
                setIntent([]);
                setTimeout(()=>{
                    setIntent(intentData)
                }, 1000)
            };               
            disable();  

        }; 
        saveUserData();
    }
        return <> <div className="intent-tag">
            <div><h3 className="intent-name">Creditor Name :</h3> <span>{creditorName}</span></div>
            <button className={`complete-btn ${pageDisabled ? 'disabled' : 'enabled' }`} onClick={onComplete}> Verification Complete</button>
            </div>
        <div className="sticky-banner">Changes cannot be made after 4:20 EST</div> { loading  ? ( //|| currentView=== undefined
            <LoadingDisplay />
        ) : <div className={`intent-page`}>  <div className='graph-btn'>
        <h3>Next Day Payment Summary</h3>
            <button onClick={() =>{
                setGraph(!graph)
            }}> Summary Dashboard</button>
        </div>
        {graph &&
         <AccumulationChartComponent id='pie-chart' 
              title='Deposit Statistics'
            >
              <Inject services={[AccumulationLegend, PieSeries, AccumulationTooltip, AccumulationDataLabel]} />
              <AccumulationSeriesCollectionDirective>
                <AccumulationSeriesDirective dataSource={pieData} xName='code' yName='value'
                  explode={true} explodeOffset='10%' explodeIndex={0}
                  dataLabelTemplate={(args: any) => {
                    return <>${args.point.label}</>
                  }}
                  dataLabel={{
                    visible: true,
                    position: 'Inside', name: 'text',
                    font: {
                      fontWeight: '600'
                    }
                  }}
                  radius='70%'
                >
                </AccumulationSeriesDirective>
              </AccumulationSeriesCollectionDirective>
            </AccumulationChartComponent>}
        <div className="summary-block">
          {codes.map((code: any, index: number) => {
              return <div key={index} className={`card ${updatedIntent[code?.reliantBankCode] || code?.reliantBankCode == 'Default'  ? '' : 'no-data'}`}>
                  <h3><span>{code?.reliantBankCode}</span>  <span className="e-icons e-circle-info" onClick={() => {
                      setShowDialog(true);
                      setModalType('Bank')
                      setBankData(code)                      
                  }}/></h3>                
                  <p>${updatedIntent[code?.reliantBankCode] ? Number(updatedIntent[code?.reliantBankCode]).toFixed(2) : 0.00}</p>
              </div>
              
           })}
        </div>
        <div className="deposit-details">
        <h3>Next Day Payment Details</h3>
        <div>
           { banks.length > 0 &&  <><label>Bank Code : </label>
            <select onChange={(e) => {
                setSelectedValue(e.target.value)
            }} value={selectedValue}>
                    {
                        banks.map((bank, index: number) => {
                            return  <option key={index} value={bank}>{bank}</option>
                        })
                    }
            </select>
            <button type="button" className={`confirm-btn ${pageDisabled ? 'disabled' : 'enabled' }`} disabled={!enableConfirm || pageDisabled} onClick={saveBankCodes}>Confirm</button></>}
            
        </div></div>
        <div className="intent-grid"><span className='e-icons e-close serach-clear' onClick={()=>{
            if (gridOptions.ref.current) {
                gridOptions.ref.current.searchSettings.key = '';
                (document.getElementById(gridOptions?.ref?.current?.element.id + '_searchbar') as any).value = '';
                gridOptions.ref.current.refresh();
            }
        }}></span>
         <GridComponent
         searchSettings={searchOptions}
        width={'1500px'}
        actionComplete={actionComplete}
        allowExcelExport={allowExcelExport}
        pdfQueryCellInfo={pdfQueryCellInfo}
        excelQueryCellInfo={excelQueryCellInfo}
        allowPdfExport={allowPdfExport}
        actionBegin={actionBegin}
        toolbar={toolbarOptions}
        toolbarClick={toolbarClick}
        ref={gridOptions.ref}
        allowFiltering={true}
        allowSorting={true}
        filterSettings={filterOptions}
        rowHeight={currentView === 3 ? 20 : 40}
        selectionSettings={selectionSettings}
        rowSelected={onRowSelected}
        rowDeselected={onRowSelected}
        rowDataBound={onRowDataBound}
        // checkBoxChange={onCheckboxChange}
        // className={`e-grid ram-grid ${currentView === 3 ? 'compact-grid' : 'consumer-grid'} ${currentView === 2 ?  'comfort-grid-view' : 'default-grid-view'}`}
        // className={`e-grid ram-grid short-settlements-grid ${currentView ===2 ? 'comfort-grid-view' : ''} ${currentView ===3 ? 'compact-view' : ''}`}
        dataBound= { dataBound } 
        commandClick={onCommandClick}
        dataSource={intent}
        clipMode='EllipsisWithTooltip'
        // allowFiltering={true}
        // allowfil
    >
        {
            
            intent.length > 0 && <ColumnsDirective>
             <ColumnDirective
                            field="select"
                            type="checkbox"
                            width="3.5%"
                            textAlign="Left"
                            allowFiltering={false}
                />
                
                 <ColumnDirective
                    headerText=""
                    field="btnEdit"
                    commands={editCommand}
                    textAlign="Left"
                    width='4.5%'
                    headerTextAlign="Left"
                    allowFiltering={false}
                    // filterSettings={{
                    //     type: 'Excel'
                    // }}

                > </ColumnDirective> 
                
                {
                    columns.map((key: string, index: number)=>{
                    const header = camelCaseToString(key);
                        return <ColumnDirective
                            key={index}
                            field={key}
                            allowFiltering={true}
                            headerText={header.substring(0,1).toUpperCase()+
                            header.substring(1)}
                            width="100px"
                            template={key==='rejected' ?(props: any) => { 
                                return <button className={props['rejected'] ?  'rejected-icon':'reject-icon'} 
                                onClick={()=>onRejectClick(props)} disabled = {pageDisabled}>{!props['rejected'] ? 'Reject' : 'Rejected'}</button>
                            } : undefined}
                        >
                        </ColumnDirective>
                    })
                }
                
                 
                    
            </ColumnsDirective>
        }
        <Inject services={[Edit, Search, CommandColumn, PdfExport, ExcelExport, Toolbar, Filter,Aggregate,Resize, Sort]} />
    </GridComponent></div></div>}

      {showDialog && <MessagePopup
        requireConfirmation={modalType === 'Reject' ? true : false}
        message={modalType === 'Reject' ? "Are you sure you want to reject this payment?" : undefined}
        showDialog= {showDialog}
        type={modalType}
        updateAccount={updateAccount}
        updateAmount={updateAmount}
        data={modalType === 'Edit' ? editedRow : bankData}
        setShowDialog={(val)=>{
            setShowDialog(val)
        }}
        onConfirmClick={(reason?:string) => {
            setReject(false);
            if(modalType!=='Edit'){
                setRejectReason(reason ?? '');
                setReject(true);
            }
            setShowDialog(false)
         }}
        onCancelClick={() => {
            if(modalType!=='Edit'){
                setReject(false)
            }
            setShowDialog(false)
        }}
    ></MessagePopup>} </>
}

